<template>
  <div
    class="accordion-box"
    :class="{'marginB': showHide}"
  >
    <div
      class="accordion-title"
      @click="operation"
    >
      <div class="accordion-h1">
        {{ title }}
      </div>
      <div
        class="accordion-imgbox"
      >
        <img
          :src="showHide ? imgsd : imgs"
          alt=""
        >
      </div>
    </div>
    <slot v-if="showHide" />
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      showHide: false,
      imgs: require('../../assets/img/icon_arrowx.png'),
      imgsd: require('../../assets/img/icon_arrows.png')
    };
  },
  methods: {
    operation() {
      this.showHide = !this.showHide;
      this.$emit('refresh');
    }
  }
};
</script>

<style lang="scss" scoped>
.accordion-box{
  position:relative;
  border-top: 1px solid transparent;
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: $color-E5;
    transform: scaleY(0.5);
    content: '';
  }
  .accordion-title{
    display: flex;
    justify-content: space-between;
    margin: 24px 0;
    .accordion-h1{
      color: $color-333;
      font-weight: 600;
      font-size: $font-16;
      line-height: 22px;
    }
    .accordion-imgbox{
      width: 24px;
      height:24px;
      img{
        width: 24px;
      }
    }
  }
}
.marginB{
  margin-bottom: 24px;
}
</style>
