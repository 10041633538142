import { render, staticRenderFns } from "./addition-comments.vue?vue&type=template&id=749821f8&scoped=true"
import script from "./addition-comments.vue?vue&type=script&lang=js"
export * from "./addition-comments.vue?vue&type=script&lang=js"
import style0 from "@/assets/css/components/addstyle.scss?vue&type=style&index=0&id=749821f8&prod&lang=scss&scoped=true&external"
import style1 from "./addition-comments.vue?vue&type=style&index=1&id=749821f8&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "749821f8",
  null
  
)

export default component.exports