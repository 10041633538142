<template>
  <div class="imgview-box">
    <div class="imgview-content" v-for="(item, index) in list" :key="index">
      <div class="imgview-imgname">
        <div class="imgview-imgbox">
          <img src="@/assets/img/person.png" alt="" />
        </div>
        <div class="imgview-name">{{item.name}}</div>
      </div>
      <div class="imgview-status" :style="matchColor(item.status)">
        {{item.status}}
      </div>
    </div>
  </div>
</template>

<script>
import { matchingColor } from '../../lib/until';

export default {
  props: {
    list: {
      type: Array,
      default() {
        return [];
      }
    }
  },
  methods: {
    matchColor(val) {
      return matchingColor(val);
    }
  }
};
</script>

<style lang="scss" scoped>
.imgview-box{
  .imgview-content{
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .imgview-imgname{
      display: flex;
      justify-content: flex-start;
      .imgview-imgbox{
        width: 40px;
        height: 40px;
        img {
          width: 40px;
        }
      }
      .imgview-name{
        font-size: $font-16;
        color: #color-999;
        align-self: center;
        margin-left: 12px;
      }
    }
    .imgview-status{
      font-size: $font-16;
      color:$color-CCC;
      align-self: center;
    }
  }
}
</style>
