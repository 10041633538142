<template>
  <div>
    <div
      class="echarts-dot"
      :style="{backgroundColor: data.color}"
    />
    <div :class=" data.text.length > 3 ? 'echarts-multitext' : 'echarts-text' ">
      {{ data.text }}
    </div>
    <div class="echarts-num">
      {{ data.num }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.echarts-dot{
  align-self: center;
  width: 6px;
  height: 6px;
  margin-right: 6px;
  background-color: #66CCA1;
  border-radius:50%;
}
.echarts-text{
  align-self: center;
  margin-right: 2px;
  color: $color-999;
  font-size: $font-12;
}
.echarts-multitext{
  align-self: center;
  width: 24px;
  margin-right: 2px;
  color: $color-999;
  font-size: $font-10;
}
.echarts-num{
  align-self: center;
  color: $color-333;
  font-size: $font-12;
}
</style>
