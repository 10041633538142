export default {
  data() {
    return {
      offsetTop: 0,
      scrollY: 0,
      tabOpacity: 0,
      scrollEvents: ['scroll', 'scroll-end'],
      disabled: false,
      // swiper
      loop: false,
      autoPlay: false,
      showDots: false,
      slideOptions: {
        listenScroll: true,
        probeType: 3,
        click: false,
        /* lock y-direction when scrolling horizontally and  vertically at the same time */
        directionLockThreshold: 0
      }
    };
  },
  computed: {
    options() {
      return {
        pullUpLoad: this.tabPullUpMap[this.initialIndex].enable,
        observeDOM: false
      };
    },
    stickyPos() {
      return this.$refs.sticky.positions[0] - this.offsetTop;
    }
  },
  watch: {
    initialIndex(newTab, oldTab) {
      // 第一件事 记录位置
      this.tabPullUpMap[oldTab].pos = this.scrollY;
      // 第二件事 重新回到之前的位置
      // 计算这个位置需要换算下
      // 因为只有一个 sticky-ele
      const stickyPos = this.stickyPos;
      const stickyPos2 = stickyPos + this.offsetTop;
      const memoryPos = this.tabPullUpMap[newTab].pos;
      let keyPos = '';
      // 如果当前位置比 stickyPos 还要大
      if (this.scrollY >= stickyPos2) {
        // 说明此时是完全 sticky 状态 opacity = 1
        keyPos = Math.max(memoryPos, stickyPos);
        // 修复半透明问题
        this.tabOpacity = 1;
      } else if (this.scrollY >= stickyPos) {
        // 说明此时是 sticky 状态 opacity 半透明
        keyPos = Math.max(memoryPos, stickyPos);
      } else {
        this.tabOpacity = 0;
        // 说明此时是 未 sticky 状态
        keyPos = this.scrollY;
      }
      this.tabChangeing = true;
      // 必须触发 scroll 事件 所以第三个参数的时间 不是 0
      this.$refs.scroll.scrollTo(0, -keyPos, 1);
      this.$nextTick(() => {
        if (this.tabPullUpMap[newTab].enable && !this[`items${newTab}`].length) {
          // 数据还是空的 主动触发
          // 其他场景 不主动触发
          // 等待 UI 高度变化 让其能够达到触发 pullup 条件
          // hack maxScrollY 此时不能 refresh 因为需要 hack sticky 状态
          // 不让其 reset position
          this.$refs.scroll.scroll.movingDirectionY = 1;
          this.$refs.scroll.scroll.maxScrollY = -keyPos;
        } else {
          // 当不能加载更多的时候 切换就不会更新数据了 所以这里手工刷新
          this.$refs.scroll.refresh();
        }
      });
    }
  },
  methods: {
    onPullingUp() {
      // 这里分别模拟不同 tab 下的请求
      const initialIndex = this.initialIndex;
      setTimeout(() => {
        if (initialIndex !== this.initialIndex) {
          // 已经在其他tab了
          this.$refs.scroll.forceUpdate();
          return;
        }
        const targetItems = `items${initialIndex}`;
        const items = this[targetItems] = this[targetItems].concat(this.tabList);
        if (items.length >= initialIndex * 20) {
          // 某个 tab 没有更多了
          this.tabPullUpMap[initialIndex].enable = false;
        }
      }, 300);
    },
    scrollHandler({ y }) {
      this.scrollY = -y;
      // if (this.initialIndex === 1) this.cancelActive();
      this.$nextTick(() => {
        if (this.$refs.tabNavs) this.$refs.tabNavs.$el.querySelector('.cube-tab-bar-slider').style.left = `${(document.body.clientWidth / this.tabList.length - 30) / 2}px`;
      });
    },
    scrollEndHandler({ y }) {
      if (this.initialIndex === 1) {
        this.$nextTick(()=>{
          const scrollerHeight = this.$refs.scroll.scroll.scrollerHeight; // 滚动条高度
          let curIndex = 0;
          let setFlag = false;
          for (let i = 1; i < this.idList.length; i++) {
            const curItem = this.idList[i];
            const preItem = this.idList[i - 1];
            const curElementTop = document.getElementById(curItem).offsetTop;
            const preElementTop = document.getElementById(preItem).offsetTop;
            if ((-y) + (document.body.offsetHeight - 53) >= scrollerHeight && (-y) < (curElementTop - 100)) {
              setFlag = true;
              curIndex = i - 1;
              let index = this.labels.indexOf(this.current); // 获得当前的labels停留的index
              if (index < curIndex) {
                // 如果小于 则需移动到响应位置
                this.current = this.labels[curIndex];
              }
              break;
            }
            if ((-y) < (curElementTop - 100) && (-y) >= (preElementTop - 100)) {
              curIndex = i - 1;
              break;
            }
          }
          !setFlag && (this.current = this.labels[curIndex]);
        });

        // this.idList.forEach((item, index) => {
        //   console.log(document.getElementById(item).offsetTop, 'document.getElementById(item).offsetTop')
        //   console.log((document.getElementById(item).offsetTop + 98) - (-y), 'offsetTop111')
        //   if ((document.getElementById(item).offsetTop + 98) - (-y) < 100) {
        //     this.current = this.labels[index];
        //   }
        // });
      }
      this.tabChangeing && this.$nextTick(() => {
        this.tabChangeing = false;
      });
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.tabNav.$el.querySelector('.cube-tab-bar-slider').style.left = `${(document.body.clientWidth / this.tabList.length - 30) / 2}px`;
    });
  }
};
