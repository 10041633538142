<template>
  <div class="progress-out">
    <div class="progress-text">
      {{ data.text }}
    </div>
    <div class="progress-num">
      {{ data.num }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.progress-out{
  text-align: left;
}
.progress-text{
  color: $color-999;
  font-size: $font-12;
  line-height: 17px;
}
.progress-num{
  margin-top: 6px;
  margin-bottom: 4px;
  color: $color-333;
  font-weight: bold;
  font-size: $font-18;
  line-height: 22px;
}
</style>
