<template>
  <section class="section-wrap">
    <div
      class="target-num-a"
      :style="{fontSize: sizeNum}"
    >
      {{ data.num }}
    </div>
    <div class="target-num-t">
      {{ data.text }}
    </div>
  </section>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    },
    sizeNum: {
      type: String,
      default: ''
    }
  }
};
</script>

<style lang="scss" scoped>
.section-wrap{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.target-num-a{
  margin-bottom:2px;
  color: $color-333;
  font-weight: bold;
  font-size: $font-18;
  line-height: 1.25;
}
.target-num-t{
  color: $color-999;
  font-size: $font-12;
  line-height: 1.45;
}
</style>
