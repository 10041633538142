<template>
  <div
    class="section comments-box-style"
    @click="focus"
  >
    <!-- <cube-textarea v-model="visitPurpose" :maxlength="maxlength" placeholder='请输入内容' autofocus class="comments-textarea"></cube-textarea> -->
    <cube-form
      :model="model"
      :options="options"
      class="form-wrapper"
      @submit="submitHandler"
    >
      <div class="textarea-boxs">
        <cube-form-item
          class="comments-textarea"
          :class="areaExpand ? 'expand' : 'close'"
          :field="fields[0]"
        >
          <cube-textarea
            ref="textarea"
            v-model="model.comment"
            placeholder="输入评论..."
            :maxlength="maxlength"
            :rows="2"
            :auto-expand="false"
            :autofocus="false"
            :disabled="false"
            :indicator="false"
            @input.native="keydown"
            @focus="focus"
          />
        </cube-form-item>
        <cube-button
          v-if="uploadImg"
          :disabled="disableOperate || model.comment === ''"
          type="submit"
          :class="model.comment !== '' ? 'btn-text' : 'btn-notText'"
        >
          发布
        </cube-button>
      </div>
      <div v-if="uploadImg">
        <cube-form-item :field="fields[1]" />
      </div>
    </cube-form>
  </div>
</template>

<script>
import config from '../../../lib/config';
import { addComment } from '_api/workreport';
import { throttle } from '_lib/until';
export default {
  name: 'AddComments',
  data() {
    return {
      disableOperate: false,
      options: {
        scrollToInvalidField: false,
        layout: 'classic' // standard fresh
      },
      maxlength: 500,
      model: {
        comment: '',
        fileId: []
      },
      fields: [
        {
          type: 'textarea',
          modelKey: 'comment',
          props: { placeholder: '请输入内容', maxlength: 500, autoExpand: true, autofocus: true, disabled: false },
          rules: { required: false }
        },
        {
          type: 'upload',
          modelKey: 'fileId',
          events: {
            'file-click': (...args) => {
              let imgs = args[0].response ? [args[0].response.data.record ? args[0].response.data.record.fileUrl : ''] : [args[0].fileUrl];
              this.$createImagePreview({
                imgs: imgs
              }).show();
            }
          },
          props: {
            max: 1,
            action: {
              target: `${config.BASE_URL}/client/tencent/getFileAndFileIdFeign`,
              headers: { token: this.$store.state.token },
              data: {
                type: 99,
                time: 0
              }
            }
          },
          messages: {
            uploaded: '上传失败'
          }
        }
      ],
      uploadImg: false, // 展示上传
      areaExpand: false,
      oriLength: 0
    };
  },
  computed: {
  },
  methods: {
    submitHandler(e) {
      e.returnValue = false;
      this.disableOperate = true;
      let params = JSON.parse(JSON.stringify(this.model));
      params.fileId = params.fileId.map(item => item.response ? item.response.data.record.fileId : item.fileId).join();
      this.$loading.show();
      addComment({...params, reportId: this.$route.params.reportId}).then(res => {
        this.$loading.hide();
        if (res.flag) {
          this.disableOperate = false;
          this.$showToast('保存成功');
          this.blur();
          this.clear();
        } else {
          this.$showToast(res.errorMsg);
        }
      });
    },
    focus() {
      this.uploadImg = true;
    },
    blur() {
      this.uploadImg = false;
      this.$refs.textarea.blur();
    },
    keydown: throttle(function(e) {
      if (e.data === '@') {
        document.activeElement.blur();
        this.$emit('selectPerson');
      }
      let curLength = this.model.comment.length;
      if ((curLength - this.oriLength) < 0) {
        this.areaExpand = false;
        this.oriLength = 0;
        return;
      }
      if (this.areaExpand) return;
      const scrollHeight = e.target.scrollHeight;
      this.areaExpand = scrollHeight > 40;
      this.oriLength = this.model.comment.length;
    }, 500),
    validity(person) {
      this.model.comment += person;
    },
    clear() {
      this.model.comment = '';
      this.model.fileId = [];
    }
  }
};
</script>

<style lang="scss" scoped src="@/assets/css/components/addstyle.scss"></style>
<style lang="scss">
.comments-box-style{

  .textarea-boxs{
    display: flex;
    .comments-textarea{
      flex: 1;
      .cube-textarea{
        line-height: 1.3;
        background: #F7F7F7;
        border-radius: 18px;
      }
      .cube-textarea-wrapper::after{
        border: 0;
      }
    }
    .btn-text{
      width: 30px;
      height: 40px;
      margin-right:15px;
      color: #00A4FF;
      background-color: transparent;
      border: 0;
      outline: none;
    }
    .btn-notText{
      width: 30px;
      height: 40px;
      margin-right:15px;
      color: #CCEDFF;
      background-color: transparent;
      border: 0;
      outline: none;
    }

  }
  .cube-upload-btn-def{
    width: 24px;
    height: 24px;
    background-color: transparent;
    background-image: url('~@/assets/img/icon_picupload.png');
    background-size: cover;
  }
  .border-bottom-1px::after{
    border: 0;
  }
  .cube-btn{
    padding: 0;
  }
  .cube-form_classic .cube-form-item {
    padding: 0 15px 10px 15px;
  }
  .cube-form_classic .cube-form-item:first-child {
    padding: 10px 15px;
  }
  .expand {
    .cube-textarea_expanded{
      height: 72px;
    }
  }
  .close {
    .cube-textarea_expanded{
      height: 40px;
    }
  }

  .cube-btn_disabled::after{
    display:none;
  }
  .cube-btn_disabled.cube-btn_active::after, .cube-btn_disabled:active::after{
    display:none;
  }
}
</style>
