<template>
  <div class="icon-view">
    <i class="icon-joyo icon-view-arrow">&#xe636;</i>
    <div class="icon-view-title">
      {{ title }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    }
  }
};
</script>

<style lang="scss" scoped>
.icon-view{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height:40px;
  background-color: $color-F7;
  border-radius: 4px;
  .icon-view-arrow{
    margin-left: 12px;
    color:$color-theme;
  }
  .icon-view-title{
    margin-left: 8px;
    color: $color-333;
    font-size: $font-16;
  }
}
</style>
