<template>
  <div class="progress-out">
    <div class="progress-box">
      <div
        ref="progressBar"
        class="progress-bar"
      >
        <div
          ref="progressPercentage"
          class="progress-percentage"
          :style="{backgroundColor: data.color, width: data.percentage * 100 + '%'}"
        />
      </div>
      <div
        v-if="isNum"
        class="progress-numerical"
      >
        {{ `${ isNaN(parseFloat(data.percentage * 100).toFixed(2)) ? 0 : parseFloat(data.percentage * 100).toFixed(2)}%` }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    },
    isNum: {
      type: Boolean,
      default: true
    }
  }
};
</script>

<style lang="scss" scoped>
.progress-out{
  text-align: left;
}
.progress-box{
  position: relative;
  display: flex;
  justify-content: flex-start;
  .progress-bar{
    flex: 1;
    align-self: center;
    width:80%;
    height: 6px;
    background-color: $color-F7;
    border-radius: 3px;
    .progress-percentage{
      height: 6px;
      background-color: $color-theme;
      border-radius: 3px;
    }
  }
  .progress-numerical{
    //width:42px;
    margin: 0 15px 0 8px;
    color: $color-999;
    font-size: $font-12;
    line-height: 17px;
  }
}
</style>
