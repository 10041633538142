<template>
  <div class="target-box">
    <div class="target-box-m">
      <!-- 团队人员情况 -->
      <div class="target-head-h1">
        团队人员情况
      </div>
      <div class="target-charts-box">
        <div class="target-charts-couple">
          <div class="target-charts-frame">
            <echartsPie
              id="in-job"
              ref="inJob"
              :data="joinInfo"
            />
            <div class="position-center-text">
              <echartsCenter :data="{num: teamObj.totalPreparation, text: '总编制'}" />
            </div>
            <div
              class="target-legend-out"
            >
              <div
                v-for="(item, index) of joinList"
                :key="index"
                :class="joinList.length > 2 ? 'legend-wrap-inline' : 'legend-wrap'"
              >
                <echartsLegend
                  class="echarts-legend"
                  :data="item"
                />
              </div>
            </div>
          </div>
          <div class="target-num">
            <echartsCenter :data="{num: teamObj.expectedResignationNum, text: '预计当月离职人数'}" />
          </div>
        </div>
        <div class="target-charts-couple">
          <div class="target-charts-frame">
            <echartsPie
              id="staff"
              ref="staff"
              :data="staffInfo"
            />
            <div class="position-center-text">
              <echartsCenter :data="{num: teamObj.onJob, text: '在职'}" />
            </div>
            <div
              class="target-legend-out"
            >
              <div
                v-for="(item, index) of legendList"
                :key="index"
                :class="legendList.length > 2 ? 'legend-wrap-inline' : 'legend-wrap'"
              >
                <echartsLegend
                  class="echarts-legend"
                  :data="item"
                />
              </div>
            </div>
          </div>
          <div class="target-num">
            <echartsCenter :data="{num: teamObj.employeesReserveNum, text: '储备人数'}" />
          </div>
        </div>
      </div>

      <!-- 关键目标完成情况 -->
      <div class="target-head-h1-1">
        关键目标完成情况
      </div>
      <div class="target-head-h2">
        在岗人次
      </div>
      <div class="target-tab">
        <div
          v-for="(item, index) of tabList"
          :key="index"
          class="target-tab-item"
          :class="page === index ? 'target-tab-active' : ''"
          @click="option(index)"
        >
          {{ item }}
        </div>
      </div>
      <div class="target-charts-case">
        <div class="target-charts-l">
          <echartsPie
            id="percentage"
            ref="percentage"
            :data="percentageInfo"
            :size="{width: '112px', height: '112px'}"
          />
          <div
            class="position-center-text"
            :style="{top: '58px'}"
          >
            <percentageText
              :data="{num: personTargetRate[0], decimal: personTargetRate[1], text: '完成率'}"
              size-num="24px"
            />
          </div>
        </div>
        <div class="target-charts-r">
          <div
            v-for="(item, index) of billboard"
            :key="index"
            class="target-charts-r-item"
          >
            <echartsCenter
              :data="{num: item.num, text: item.text}"
            />
          </div>
        </div>
      </div>

      <!-- 营收指标 -->
      <div class="target-head-h1-1">
        营收指标
      </div>
      <div class="target-charts-box">
        <div class="target-charts-progress">
          <div class="target-charts-progress-item">
            <echartsTextProgress :data="{text: '月度已完成(元)', num: targetCondition.weekOfMonthRevenues}" />
            <echarts-progress :data="weekOfMonthRevenuesCompletedRate" />
          </div>
        </div>
        <div class="target-charts-progress">
          <div class="target-charts-progress-item">
            <echartsTextProgress :data="{text: '年度已完成(元)', num: targetCondition.weekOfYearRevenues}" />
            <echarts-progress :data="weekOfYearRevenuesCompletedRate" />
          </div>
        </div>
      </div>
      <!-- 毛利指标 -->
      <div class="target-head-h1-1">
        毛利指标
      </div>
      <div class="target-charts-box">
        <div class="target-charts-progress">
          <div class="target-charts-progress-item">
            <echartsTextProgress :data="{text: '月度已完成(元)', num: targetCondition.weekOfMonthGrossProfits}" />
            <echarts-progress :data="weekOfMonthGrossProfitsCompletionRate" />
          </div>
        </div>
        <div class="target-charts-progress">
          <div class="target-charts-progress-item">
            <echartsTextProgress :data="{text: '年度已完成(元)', num: targetCondition.weekOfYearGrossProfits}" />
            <echarts-progress :data="weekOfYearGrossProfitsCompletionRate" />
          </div>
        </div>
      </div>
      <!-- 拜访情况 -->
      <div class="target-head-h1-1">
        拜访情况
      </div>
      <div class="target-bulletin">
        <div class="target-month-text">
          月度拜访目标
        </div>
        <div class="target-month-num">
          {{ visitStatistics.monthlyTrackGoal }}
        </div>
      </div>
      <echarts-visit
        :data="{ icon: '\ue631', text: '本月拜访量', num: visitStatistics.monthlyTrackTotal, percentage: parseFloat((monthlyTrackRate.percentage *100).toFixed(2)) + '%'}"
        :is-progress="true"
      >
        <echarts-progress
          :data="monthlyTrackRate"
          :is-num="false"
        />
      </echarts-visit>
      <echarts-visit
        :data="{ icon: '\ue631', text: '本月陪访量', num: visitStatistics.monthlyAccompanyTrackTotal}"
        :is-progress="false"
      />
      <echarts-visit
        :data="{ icon: '\ue632', text: '本周拜访量', num: visitStatistics.weeklyTrackTotal, percentage: parseFloat((weekTrackRate.percentage *100).toFixed(2)) + '%'}"
        :is-progress="true"
      >
        <echarts-progress
          :data="weekTrackRate"
          :is-num="false"
        />
      </echarts-visit>
    </div>
  </div>
</template>

<script>
import echartsPie from '_c/echarts/echarts-pie';
import echartsLegend from '_c/echarts/echarts-legend';
import echartsCenter from '_c/echarts/echarts-center';
import echartsProgress from '_c/echarts/echarts-progress';
import echartsTextProgress from '_c/echarts/echarts-textprogress';
import echartsVisit from '_c/echarts/echarts-visit';
import percentageText from '_c/echarts/percentage-text';
import { toMoney} from '@/lib/until.js';
export default {
  name: 'ReviewTarget',
  components: {
    echartsPie,
    echartsLegend,
    echartsCenter,
    echartsProgress,
    echartsTextProgress,
    echartsVisit,
    percentageText
  },
  data() {
    return {
      joinInfo: {
        color: ['#66CCA1', '#00A4FF', '#fac858', '#F5746E', '#F5746E'],
        num: [],
        text: ['在职', '缺编']
      },
      staffInfo: {
        color: ['#66CCA1', '#00A4FF', '#fac858', '#F5746E', '#F5746E'],
        num: [],
        text: ['分总', '项目执行', '销售', '服务']
      },
      percentageInfo: {
        color: ['#00A4FF', '#F7F7F7'],
        num: []
      },
      page: 0,
      tabList: ['月度', '年度'],
      billboard: [{num: '780,000', text: '目标'}, {num: '695,000', text: '缺口'}, {num: '3,857', text: '人均产能'}, {num: '85,000', text: '已完成'}],
      // 团队人员情况对象
      teamObj: {},
      // 关键目标完成情况
      targetCondition: {},
      personTargetRate: [0, 0],
      monthPersonTargetRateList: [0, 0],
      yearPersonTargetRateList: [0, 0],
      monthTargetRate: {},
      yearTargetRate: {},
      monthBillboard: {},
      yearBillboard: {},
      // 营收指标
      weekOfMonthRevenuesCompletedRate: {},
      weekOfYearRevenuesCompletedRate: {},
      weekOfMonthGrossProfitsCompletionRate: {},
      weekOfYearGrossProfitsCompletionRate: {},
      // 拜访情况
      visitStatistics: {},
      monthlyTrackRate: {}, // 本月拜访比例
      weekTrackRate: {}
    };
  },
  computed: {
    joinList() {
      let list = [];
      this.joinInfo.num.forEach((item, index) => {
        list.push({color: this.joinInfo.color[index], text: this.joinInfo.text[index], num: item.value});
      });
      return list;
    },
    legendList() {
      let list = [];
      this.staffInfo.num.forEach((item, index) => {
        list.push({color: this.staffInfo.color[index], text: this.staffInfo.text[index], num: item.value});
      });
      return list;
    }
  },
  methods: {
    option(index) {
      this.page = index;
      if (index === 1) {
        this.billboard = this.yearBillboard;
        this.percentageInfo.num = this.yearTargetRate;
        this.personTargetRate = this.yearPersonTargetRateList;
        this.$refs.percentage.drawLine(this.percentageInfo);
      } else {
        this.billboard = this.monthBillboard;
        this.percentageInfo.num = this.monthTargetRate;
        this.personTargetRate = this.monthPersonTargetRateList;
        this.$refs.percentage.drawLine(this.percentageInfo);
      }
    },
    getData(data) {
      // 团队人员情况
      this.teamObj = data.teamPersonnelSituation;
      this.joinInfo.num = [{value: this.teamObj.onJob}, {value: this.teamObj.employeesMissingNum}];
      this.staffInfo.num = [{value: this.teamObj.subTotal}, {value: this.teamObj.projectExecution}, {value: this.teamObj.sales}, {value: this.teamObj.service}];
      this.$refs.inJob.drawLine(this.joinInfo);
      this.$refs.staff.drawLine(this.staffInfo);
      // 关键目标完成情况
      this.targetCondition = data.keyObjectCompletionStatus;
      let monthPersonTargetRate = this.targetCondition.monthPersonTargetRate ? ((this.targetCondition.monthPersonTargetRate * 10000) / 100) + '' : '';
      let yearPersonTargetRate = this.targetCondition.yearPersonTargetRate ? ((this.targetCondition.yearPersonTargetRate * 10000) / 100) + '' : '';
      if (this.targetCondition.monthPersonTargetRate) {
        const nums = monthPersonTargetRate.split('.');
        const num = nums[0];
        const decimal = nums[1];
        let decimalValue = '';
        if (decimal && decimal.length >= 2) {
          decimalValue = decimal.substring(0, 2);
        }
        if (decimal && decimal.length < 2) {
          decimalValue = decimal.substring(0, decimal.length);
        }
        this.monthPersonTargetRateList = [num, decimalValue];
      }
      if (this.targetCondition.yearPersonTargetRate) {
        const nums = yearPersonTargetRate.split('.');
        const num = nums[0];
        const decimal = nums[1];
        let decimalValue = '';
        if (decimal && decimal.length >= 2) {
          decimalValue = decimal.substring(0, 2);
        }
        if (decimal && decimal.length < 2) {
          decimalValue = decimal.substring(0, decimal.length);
        }
        this.yearPersonTargetRateList = [num, decimalValue];
      }
      this.monthTargetRate = [{value: this.targetCondition.monthPersonTargetRate || 0}, {value: 1 - (this.targetCondition.monthPersonTargetRate || 0)}];
      this.yearTargetRate = [{value: this.targetCondition.yearPersonTargetRate || 0}, {value: 1 - (this.targetCondition.yearPersonTargetRate || 0)}];
      this.monthBillboard = [
        {num: toMoney(this.targetCondition.monthPersonTarget, false, false), text: '目标'},
        {num: toMoney(this.targetCondition.monthPersonGap, false, false), text: '缺口'},
        {num: toMoney(this.targetCondition.monthPersonAverage, false, false), text: '人均产能'},
        {num: toMoney(this.targetCondition.monthPersonTargetCompletion, false, false), text: '已完成'}
      ];
      this.yearBillboard = [
        {num: toMoney(this.targetCondition.yearPersonTarget, false, false), text: '目标'},
        {num: toMoney(this.targetCondition.yearPersonGap, false, false), text: '缺口'},
        {num: toMoney(this.targetCondition.yearPersonAverage, false, false), text: '人均产能'},
        {num: toMoney(this.targetCondition.yearPersonTargetCompletion, false, false), text: '已完成'}
      ];
      this.billboard = this.monthBillboard;
      this.percentageInfo.num = this.monthTargetRate;
      this.personTargetRate = this.monthPersonTargetRateList;
      this.$refs.percentage.drawLine(this.percentageInfo);
      // 本月营收指标
      this.targetCondition.weekOfMonthRevenues = toMoney(data.keyObjectCompletionStatus.weekOfMonthRevenues, false, false);
      this.targetCondition.weekOfMonthGrossProfits = toMoney(data.keyObjectCompletionStatus.weekOfMonthGrossProfits, false, false);
      this.targetCondition.weekOfYearRevenues = toMoney(data.keyObjectCompletionStatus.weekOfYearRevenues, false, false);
      this.targetCondition.weekOfYearGrossProfits = toMoney(data.keyObjectCompletionStatus.weekOfYearGrossProfits, false, false);
      this.weekOfMonthRevenuesCompletedRate = {
        percentage: this.targetCondition.weekOfMonthRevenuesCompletedRate,
        longest: 1 - this.targetCondition.weekOfMonthRevenuesCompletedRate
      };
      this.weekOfYearRevenuesCompletedRate = {
        percentage: this.targetCondition.weekOfYearRevenuesCompletedRate,
        longest: 1 - this.targetCondition.weekOfYearRevenuesCompletedRate,
        color: '#66CCA1'
      };
      this.weekOfMonthGrossProfitsCompletionRate = {
        percentage: this.targetCondition.weekOfMonthGrossProfitsCompletionRate,
        longest: 1 - this.targetCondition.weekOfMonthGrossProfitsCompletionRate
      };
      this.weekOfYearGrossProfitsCompletionRate = {
        percentage: this.targetCondition.weekOfYearGrossProfitsCompletionRate,
        longest: 1 - this.targetCondition.weekOfYearGrossProfitsCompletionRate,
        color: '#66CCA1'
      };
      // 拜访情况
      this.visitStatistics = data.visitStatistics;
      this.monthlyTrackRate = {
        percentage: isNaN(data.visitStatistics.monthlyTrackTotal / data.visitStatistics.monthlyTrackGoal) ? 0 : (data.visitStatistics.monthlyTrackTotal / data.visitStatistics.monthlyTrackGoal),
        longest: 1 - this.targetCondition.weekOfYearGrossProfitsCompletionRate
      };
      this.weekTrackRate = {
        percentage: isNaN(data.visitStatistics.weeklyTrackTotal / data.visitStatistics.monthlyTrackGoal) ? 0 : (data.visitStatistics.weeklyTrackTotal / data.visitStatistics.monthlyTrackGoal),
        longest: 1 - this.targetCondition.weekOfYearGrossProfitsCompletionRate
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.target-box {
  width:100%;
  overflow: hidden;
  background-color: $color-FFF;
  .target-box-m{
    margin: 0 15px;
    .target-head-h1{
      margin: 20px 0 14px 0;
      color: $color-333;
      font-weight: 600;
      font-size: $font-18;
      line-height: 1.4;
      text-align: left;
    }
    .target-head-h1-1{
      @extend .target-head-h1;

      margin: 30px 0 14px 0;
    }
    .target-head-h2{
      margin: 2px 0 14px 0;
      color: $color-333;
      font-weight: 600;
      font-size: $font-16;
      line-height: 1.4;
      text-align: left;
    }
    .target-charts-box{
      display: flex;
      justify-content: space-between;
      width:100%;
      .target-charts-couple{
        width: 49%;
        .target-legend-out{
          height: 56px;
          margin-top: 16px;
          padding: 0 10px;
          .legend-wrap{
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .legend-wrap-inline{
            display: inline-block;
            width: 50%;
          }
          .echarts-legend{
            display: flex;
            height: 28px;
            //margin-bottom: 10px;
          }
          .flexBasic50{
            //flex: 0 0 50%;
          }
          //.target-legend-box{
          //  display: flex;
          //  flex-wrap: wrap;
          //  justify-content: space-around;
          //  margin-left: 12px;
          //  .echarts-legend{
          //    display: flex;
          //    flex: 0 0 50%;
          //    justify-content: flex-start;
          //    margin-bottom: 10px;
          //  }
          //}
          //.target-legend-item{
          //  .echarts-legend {
          //    display: flex;
          //    flex: 0 0 100%;
          //    margin-bottom: 10px;
          //  }
          //}
        }
        .flexColumn{
          flex-direction: column;
        }
        .flexWrap{
          flex-wrap: wrap;
        }
      }
      .target-charts-frame{
        position: relative;
        width: 100%;
        height: 190px;
        margin-bottom: 10px;
        overflow: hidden;
        background: $color-FFF;
        border-radius: 8px;
        box-shadow: 0 2px 8px 0 rgba(90, 3, 3, 0.06);
      }
      .target-charts-progress{
        width: 49%;
        height: 86px;
        overflow: hidden;
        background: $color-FFF;
        border-radius: 8px;
        box-shadow: 0 2px 8px 0 rgba(0,0,0,0.06);
        .target-charts-progress-item{
          margin: 12px 0 0 15px;
        }
      }
    }
    .target-charts-case{
      display: flex;
      justify-content: flex-start;
      width: 100%;
      .target-charts-l{
        position: relative;
        width: 126px;
      }
      .target-charts-r{
        display: flex;
        flex: 1;
        flex-wrap: wrap;
        justify-content: space-between;
        .target-charts-r-item {
          flex: 0 0 49.6%;
          height: 32px;
          margin-top: 26px;
          border-right: 1px solid $color-E5;
        }
        .target-charts-r-item:nth-child(2n) {
          border: 0;
        }
      }
    }
    .target-num{
      width: 100%;
      //height: 65px;
      //height: 52px;
      padding: 12px 0;
      overflow: hidden;
      text-align: center;
      background-color: $color-FFF;
      border-radius: 8px;
      box-shadow: 0 2px 8px 0 rgba(0,0,0,0.06);
    }
    .position-center-text{
      position: absolute;
      top: 48px;
      left: 50%;
      transform: translate(-50%, 0);
    }
    .target-tab{
      display: flex;
      width: 100%;
      height: 32px;
      padding: 2px;
      background-color: $color-F7;
      border-radius: 8px;
    }
    .target-tab-item{
      align-self: center;
      width:50%;
      color: $color-333;
      font-weight: 600;
      font-size: $font-12;
      line-height: 34px;
      text-align: center;
    }
    .target-tab-active{
      //align-self: center;
      margin: 2px 0;
      background-color: $color-FFF;
      border-radius: 6px;
    }
    .target-bulletin{
      display: flex;
      align-items: center;
      justify-content: space-between;
      width:100%;
      height: 44px;
      margin-bottom: 20px;
      background-color: $color-F7;
      border-radius: 8px;
      .target-month-text{
        margin-left: 15px;
        color: $color-333;
      }
      .target-month-num{
        margin-right: 15px;
        color: $color-333;
        font-weight: bold;
      }
    }
  }
}

</style>
