export default [
  {
    num: '01',
    title: '评估结果',
    list: [
      {
        tabName: '项目亮点',
        anchor: 'excellentProject',
        activate: false
      },
      {
        tabName: '项目不足',
        anchor: 'poorProject',
        activate: false
      },
      {
        tabName: '项目变化项',
        activate: false,
        anchor: 'changeProject'
      },
      {
        tabName: '标杆案例',
        activate: false,
        anchor: 'templateProject'
      }
    ]
  },
  {
    num: '02',
    title: '分析原因',
    list: [
      {
        tabName: '成功原因',
        anchor: 'reasonSuccess',
        activate: false
      },
      {
        tabName: '失败原因',
        anchor: 'reasonFailure',
        activate: false
      }
    ]
  },
  {
    num: '03',
    title: '下一步行动',
    list: [
      {
        tabName: '开始做',
        anchor: 'nextFirst',
        activate: false
      },
      {
        tabName: '持续做',
        anchor: 'nextPersistent',
        activate: false
      },
      {
        tabName: '停止做',
        anchor: 'nextStop',
        activate: false
      }
    ]
  },
  {
    num: '04',
    title: '本周拜访情况',
    list: [
      {
        tabName: '本周拜访信息',
        anchor: 'visitSituation',
        activate: false
      }
    ]
  },
  {
    num: '05',
    title: '烦恼',
    list: [
      {
        tabName: '我的烦恼',
        anchor: 'trouble',
        activate: false
      }
    ]
  },
  {
    num: '06',
    title: '总结经验',
    list: [
      {
        tabName: '总结规律及思考',
        anchor: 'sumUpExperience',
        activate: false
      }
    ]
  },
  {
    num: '07',
    title: '补充说明',
    list: [
      {
        tabName: '未阐述的内容',
        anchor: 'remark',
        activate: false
      }
    ]
  }
];
