<template>
  <div class="card-box">
    <div class="card-cont">
      <div class="card-imgbox">
        <div class="card-img">
          <img src="@/assets/img/person.png" alt="" />
        </div>
      </div>
      <div class="card-content">
        <div class="card-a">
          {{data.staffName}}
        </div>
        <div class="card-b">
          <span class="card-nb-name">{{`${data.orgTitle}-${data.postName}`}}</span>
          <span class="card-nb-time">{{data.createTime}}</span>
        </div>
        <div class="card-c">
          <div :class="{'card-c-text' : true, 'multiline-ellipsis' : unfoldBool}" ref="cardCText">
            {{data.content}}
          </div>
          <div v-if="unfoldBool && data.content.length > 90" class="card-c-unfold" @click="unfold">展开</div>
        </div>
        <div class="card-d" v-if="data.image">
          <div class="look-image" @click="showImagePreview([data.image])" v-if="isImg()">
            <img :src="data.image" alt="">
          </div>
          <div class="card-d-else" v-else>
            <span class="card-d-a" @click="toFileDownLoad">{{`[文件]${data.fileName}`}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
export default {
  props: {
    data: {
      type: Object
    }
  },
  data() {
    return {
      unfoldBool: true
    };
  },
  methods: {
    ...mapMutations('workreport', ['FILE_DOWN_LOAD']),
    unfold() {
      this.unfoldBool = false;
    },
    isImg() {
      if (this.data.image) {
        let type = this.data.fileUrl.match(/([^.]+)$/)[1];
        if (['doc', 'pdf', 'zip'].includes(type)) {
          return false;
        }
        return true;
      }
    },
    toFileDownLoad() {
      this.FILE_DOWN_LOAD({fileUrl: this.data.image, fileName: this.data.fileName});
      this.$router.push(
        {
          name: 'FileDownLoad'
        }
      );
    },
    showImagePreview(imgs) {
      this.$createImagePreview({
        imgs: imgs
      }).show();
    }
  }
};
</script>

<style lang="scss" scoped>
.card-box {
  width: 100%;
  background-color: $color-FFF;
  .card-cont {
    display: flex;
    justify-content: flex-start;
    padding: 10px 15px;
    .card-imgbox {
      width: 30px;
      height: 30px;
      background-color: $color-01;
      border-radius: 50%;
      margin-right: 8px;
      img {
        width: 30px;
        border-radius: 50%;
      }
    }
    .card-content {
      flex: 1;
      text-align: left;
      .card-a {
        color: $color-333;
        font-size: $font-14;
        line-height: 20px;
        margin-bottom: 2px;
      }
      .card-b {
        font-size: $font-10;
        color: $color-999;
        text-align: left;
        line-height: 14px;
        margin-bottom: 8px;
        .card-nb-time{
          margin-left: 10px;
        }
      }
      .card-c {
        position: relative;
        margin-bottom: 4px;
        .card-c-text{
          font-size: $font-16;
          color: $color-333;
          line-height: 22px;
        }
        .card-c-unfold{
          position: absolute;
          right:0;
          bottom: 0;
          width:80px;
          height: 22px;
          color:$color-theme;
          font-size: $font-16;
          text-align: right;
          line-height: 22px;
          background-image: url('../../../assets/img/img_zhankai_bg.png');
          background-size: 80px 22px;
        }
      }
      .card-d{
        display: flex;
        justify-content: flex-start;
        .look-image{
          width:96px;
          height: 96px;
          img{
            width:96px;
            height: 96px;
          }
        }
      }
      .card-d-else{
        width: 100%;
        background-color: $color-F7;
        .card-d-a{
          font-size: $font-12;
          padding: 4px 10px;
          color: $color-666;
          line-height: 17px;
        }
      }
    }
  }
}
</style>
