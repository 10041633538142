<template>
  <div :class="{'card-box': true, backgroundColor : backgroundColorChange}">
    <div class="card-cont">
      <div
        class="card-imgbox"
        @click="showImagePreview([data.fileUrl])"
      >
        <div class="card-img">
          <img
            :src="fileTypeImg()"
            alt=""
          >
        </div>
      </div>
      <div class="card-content">
        <div
          class="card-text"
          @click="showImagePreview([data.fileUrl])"
        >
          <div class="card-a">
            {{ data.fileName }}
          </div>
          <div class="card-b">
            <span class="card-b-text">{{ data.name }}</span>
            <span class="card-b-time">{{ data.createTime }}</span>
            <!-- <span class="card-b-time">3.2M</span> -->
          </div>
        </div>
        <a
          class="card-download"
          :href="data.fileUrl"
          :download="data.fileUrl"
        >
          <img
            :src="backgroundColorChange ? downImg : downImgs"
            alt=""
          >
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
export default {
  props: {
    data: {
      type: Object,
      default: ()=> {
        return {};
      }
    },
    backgroundColorChange: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      downImg: require('../../assets/img/icon_list_download_fff.png'),
      downImgs: require('../../assets/img/icon_list_delete.png'),
      fileImg: require('../../assets/img/img_doc.png'),
      fileTypeImgObj: {
        'doc': require('../../assets/img/img_doc.png'),
        'pdf': require('../../assets/img/img_pdf.png'),
        'png': require('../../assets/img/img_pic.png'),
        'jpg': require('../../assets/img/img_pic.png'),
        'zip': require('../../assets/img/img_zip.png'),
        'else': require('../../assets/img/img_else.png')
      }
    };
  },
  methods: {
    ...mapMutations('workreport', ['FILE_DOWN_LOAD']),
    fileTypeImg() {
      if (this.data.fileUrl) {
        let type = this.data.fileUrl.match(/([^.]+)$/)[1];
        for (let i in this.fileTypeImgObj) {
          if (type === i) {
            return this.fileTypeImgObj[i];
          }
        }
        return this.fileTypeImgObj['else'];
      }
    },
    showImagePreview(imgs) {
      let imgType = imgs.join().match(/([^.]+)$/)[1];
      if (['png', 'PNG', 'jpg', 'JPG', 'jpeg', 'JPEG'].includes(imgType)) {
        this.$createImagePreview({
          imgs: imgs
        }).show();
      } else {
        // this.$showToast('此格式暂不支持预览');
        this.toFileDownLoad();
      }
    },
    toFileDownLoad() {
      this.FILE_DOWN_LOAD({fileUrl: this.data.fileUrl, fileName: this.data.fileName});
      this.$router.push(
        {
          name: 'FileDownLoad'
        }
      );
    }
  }
};
</script>

<style lang="scss" scoped>
.card-box {
  width: 100%;
  background-color: $color-FFF;
  .card-cont {
    display: flex;
    justify-content: flex-start;
    padding: 10px 15px;
    .card-imgbox {
      align-self: center;
      width: 24px;
      height: 24px;
      margin: 0 15px 0 0;
      background-color: $color-01;
      border-radius: 50%;
      img {
        width: 24px;
      }
    }
    .card-content {
      display: flex;
      flex: 1;
      justify-content: space-between;
      .card-text {
        text-align: left;
        .card-a {
          width:220px;
          margin-bottom: 2px;
          color: $color-333;
          font-size: $font-16;
          line-height: 22px;
          @extend .one-ellipsis;
        }
        .card-b {
          color: $color-999;
          font-size: $font-14;
          line-height: 20px;
          text-align: left;
          .card-b-time{
            margin-left: 10px;
          }
        }
      }
      .card-download{
        align-self: center;
        width: 24px;
        height: 24px;
        img{
          width:24px;
        }
      }
    }
  }
}
.backgroundColor{
  background-color : $color-F7;
  border-radius : '4px';
}
</style>
