<template>
  <cube-page
    type="sticky-view-complex"
    title="Sticky"
  >
    <template slot="content">
      <div
        class="sticky-view-container"
        :style="{
          bottom: buttonState ? 59/16 + 'rem' : 0
        }"
        @click="blur"
      >
        <cube-sticky
          ref="sticky"
          :pos="scrollY"
          :offset="offsetTop"
        >
          <cube-scroll
            ref="scroll"
            :data="scrollData"
            :scroll-events="scrollEvents"
            :options="options"
            @scroll="scrollHandler"
            @scroll-end="scrollEndHandler"
            @pulling-up="onPullingUp"
          >
            <cust-header
              :data="workreportInfo"
              :diminish="true"
              @checkReport="checkReport"
            />
            <div class="heder-gap" />
            <cube-sticky-ele>
              <cube-tab-bar
                ref="tabNav"
                v-model="initialIndex"
                show-slider
                inline
                :use-transition="disabled"
                @change="changePage"
              >
                <cube-tab
                  v-for="item in tabList"
                  :key="item.value"
                  :label="item.value"
                >
                  <div class="tabBtn">
                    {{ item.label }}
                    <i
                      v-if="needIcon && item.num"
                      class="tabIcon"
                    >{{ item.num }}</i>
                  </div>
                </cube-tab>
              </cube-tab-bar>

              <div
                v-if="initialIndex === 1"
                class="side-crosswise"
              >
                <cube-scroll-nav-bar
                  :current="current"
                  :labels="labels"
                  :options="scrollOptions"
                  @change="changeHandler"
                >
                  <div
                    slot-scope="props"
                    class="tabBar-item"
                    :class="{'tabBar-active': props.label === current }"
                    @click="move(props.label)"
                  >
                    <i class="tabBar-text">{{ props.txt }}</i>
                  </div>
                </cube-scroll-nav-bar>
              </div>
            </cube-sticky-ele>
            <div
              class="tab-slide-container"
            >
              <cube-slide
                ref="slide"
                :loop="loop"
                :initial-index="initialIndex"
                :auto-play="autoPlay"
                :show-dots="showDots"
                :options="slideOptions"
                :style="{height: tabList[initialIndex].height ? tabList[initialIndex].height + 'px' : 'auto'}"
                @scroll="scroll"
                @change="changePage"
              >
                <cube-slide-item
                  v-for="(item, index) of tabList"
                  :key="index"
                  :class="needBorder && item.tableData ? 'cube-slide-list' : ''"
                >
                  <ul
                    v-if="index === 0"
                    class="list-wrapper"
                  >
                    <review-target
                      ref="reviewTarget"
                    />
                  </ul>
                  <ul
                    v-if="index === 1"
                    class="list-wrapper"
                  >
                    <review-details
                      ref="reviewDetails"
                      :project-detail-res-dto="projectDetailResDto"
                      :project-detail-files="projectDetailFiles"
                      :visit-detail-res-dtos="visitDetailResDtos"
                      :current="current"
                      @changeHandler="changeHandler"
                      @refresh="refresh"
                    />
                  </ul>
                  <ul
                    v-if="index === 2"
                    ref="commentWrap"
                    class="list-wrapper"
                  >
                    <review-records
                      v-for="(items, indexs) of comments"
                      ref="reviewRecords"
                      :key="indexs"
                      :data="items"
                    />
                    <error-page
                      v-if="!comments.length"
                      class="errorPage"
                    />
                  </ul>
                  <ul
                    v-if="index === 3"
                    ref="fileCommentWrap"
                    class="list-wrapper"
                  >
                    <related-accessory
                      v-for="(items, indexs) of fileComments"
                      ref="relatedAccessory"
                      :key="indexs"
                      :data="items"
                    />
                    <error-page
                      v-if="!fileComments.length"
                      class="errorPage"
                    />
                  </ul>
                </cube-slide-item>
              </cube-slide>
            </div>
          </cube-scroll>
          <template
            slot="fixed"
            slot-scope="props"
          >
            <cube-tab-bar
              v-if="props.index >= 0"
              ref="tabNavs"
              v-model="initialIndex"
              show-slider
              inline
              :use-transition="disabled"
              @change="changePage"
            >
              <cube-tab
                v-for="item in tabList"
                :key="item.value"
                :label="item.value"
              >
                <div class="tabBtn">
                  {{ item.label }}
                  <i
                    v-if="needIcon && item.num"
                    class="tabIcon"
                  >{{ item.num }}</i>
                </div>
              </cube-tab>
            </cube-tab-bar>
            <div
              v-if="props.index >= 0 && initialIndex === 1"
              class="side-crosswise"
            >
              <cube-scroll-nav-bar
                :current="current"
                :labels="labels"
                :options="scrollOptions"
                @change="changeHandler"
              >
                <div
                  slot-scope="propsd"
                  class="tabBar-item"
                  :class="{'tabBar-active': propsd.label === current }"
                  @click="move(propsd.label)"
                >
                  <i class="tabBar-text">{{ propsd.txt }}</i>
                </div>
              </cube-scroll-nav-bar>
            </div>
          </template>
        </cube-sticky>
      </div>
      <div
        v-if="buttonState"
        class="comments-postion"
      >
        <addition-comments
          ref="additionComments"
          @selectPerson="selectPerson"
        />
      </div>
      <!-- 查看报告人 -->
      <custom-popup
        ref="customPopups"
        :title="popTitle"
      >
        <popup-header
          slot="header"
          :title="popTitle"
        />
        <div
          slot="content"
          class="dialog-detail"
        >
          <div
            v-if="primarySender.length"
            class="dialog-content"
          >
            <div class="dialog-content-title">
              主发送人员
            </div>
            <img-view :list="primarySender" />
          </div>
          <div
            v-if="recipientList.length"
            class="dialog-content"
          >
            <div class="dialog-content-title">
              抄送人员
            </div>
            <img-view :list="recipientList" />
          </div>
        </div>
      </custom-popup>
      <!-- 评论@选择人 -->
      <custom-popup
        ref="aitPersonPop"
        :title="popTitle"
      >
        <popup-header
          slot="header"
          title="选择要@的人"
        />
        <div
          slot="content"
          class="dialog-detail"
        >
          <ul class="filter-ul">
            <select-check
              v-for="(item, index) of aitPersonList"
              :key="index"
              :data="item"
              @click.native="subscriptOperat(index)"
            />
          </ul>
        </div>
      </custom-popup>
    </template>
  </cube-page>
</template>

<script type="text/ecmascript-6">
import { mapGetters, mapMutations } from 'vuex';

import { weekReportDeatail } from '_api/workreport';
import CubePage from '_c/clue-page';
import custHeader from '_c/cust-header/header';
import errorPage from '_c/errorPage';
import selectCheck from '_c/picker/select-check';
import customPopup from '_c/popup/custom-popup';
import popupHeader from '_c/popup/popup-header';
import relatedAccessory from '_c/tab-card/related-accessory';
import imgView from '_c/view-match/img-view';

import { getUrlParam } from '@/lib/until';
import { ease, ModalHelper } from '@/lib/until.js';
import btnAuth from '@/mixins/btnAuth';
import stickyPage from '@/mixins/sticky-page';
import additionComments from '@/views/workreport/components/addition-comments';
import reviewDetails from '@/views/workreport/components/review-detailt';
import reviewRecords from '@/views/workreport/components/review-records';
import reviewTarget from '@/views/workreport/components/review-targets';
import catalogueData from './data/catalogueData';

export default {
  name: 'WorkreportDetails',
  components: {
    CubePage,
    custHeader,
    customPopup,
    popupHeader,
    imgView,
    reviewTarget,
    reviewRecords,
    relatedAccessory,
    reviewDetails,
    errorPage,
    additionComments,
    selectCheck
  },
  mixins: [stickyPage, btnAuth],
  data() {
    return {
      initialIndex: 0,
      offsetTop: 0,
      scrollY: 0,
      scrollEvents: ['scroll', 'scroll-end'],
      items0: [],
      items1: [],
      items2: [],
      items3: [],
      tabPullUpMap: {
        0: {
          enable: true,
          pos: 0
        },
        1: {
          enable: true,
          pos: 0
        },
        2: {
          enable: true,
          pos: 0
        },
        3: {
          enable: true,
          pos: 0
        }
      },
      tabs: [
        {
          label: 'tab1',
          value: 1
        },
        {
          label: 'tab2',
          value: 2
        },
        {
          label: 'tab3',
          value: 3
        }
      ],
      popTitle: '',
      primarySender: [],
      recipientList: [],
      DirectoryList: catalogueData,
      needIcon: false,
      needBorder: false,
      tabList: [{
        label: '回顾目标',
        value: 0,
        height: '1220'
      }, {
        label: '报告详情',
        value: 1
      }, {
        label: '评论记录',
        value: 2,
        height: '600'
      }, {
        label: '相关附件',
        value: 3,
        height: '600'
      }],
      // 头部信息
      workreportInfo: {},
      // 回顾目标
      reviewGoals: {},
      // 评论记录
      comments: [],
      // 相关附件
      fileComments: [],
      // 报告详情
      projectDetailResDto: {},
      projectDetailFiles: {},
      visitDetailResDtos: [],
      directoryList: [],
      // cube-scroll-nav-bar
      current: '评估结果',
      labels: [
        '评估结果',
        '分析原因',
        '下一步行动',
        '本周拜访情况',
        '烦恼',
        '总结经验',
        '补充说明'
      ],
      idList: [
        'commentResults',
        'analyzeCause',
        'nextAction',
        'visitThisWeek',
        'annoyance',
        'sumupExperience',
        'supplementaryNotes'
      ],
      scrollOptions: {
        stopPropagation: true
      },
      // 评论@选择人列表
      aitPersonList: []
    };
  },
  computed: {
    ...mapGetters(['dictList', 'userInfo']),
    needBtn() {
      return true;
    },
    scrollData() {
      return [...this.items0, ...[this.reviewGoals], [...this.items1, ...[this.projectDetailResDto]], [...this.items2, ...this.comments], [...this.items3, ...this.fileComments]];
    },
    buttonState() {
      return (this.$route.params.type === '0' && this.workreportInfo.status !== 1) || this.$route.params.type === '1';
    }
  },
  mounted() {
    this.getDetail();
    const tabName = getUrlParam('locateTab');
    if (tabName && tabName === 'commentRecord') {
      this.initialIndex = 2;
    }
  },
  methods: {
    ...mapMutations(['SET_KEEPALIVE']),
    getDetail() {
      weekReportDeatail(this.$route.params.reportId).then((res) => {
        if (res.flag) {
          // 头部信息
          const reportDetailResDto = res.data.reportDetailResDto;
          const year = reportDetailResDto.endWeekTime.slice(0, 4);
          const month = reportDetailResDto.endWeekTime.slice(5, 7);
          this.workreportInfo = {
            id: reportDetailResDto.id,
            time: year + '年' + month + '月 第' + reportDetailResDto.weekNum + '周',
            title: reportDetailResDto.staffName,
            company: reportDetailResDto.orgTitle,
            status: reportDetailResDto.status,
            creatTime: reportDetailResDto.commitTime
          };
          // 回顾目标
          this.reviewGoals = res.data.dataMap.reviewGoals;
          this.$nextTick(() => {
            this.$refs.reviewTarget[0].getData(this.reviewGoals);
          });
          // 报告详情
          // this.projectDetailResDto = {...res.data.projectDetailResDto, remark: reportDetailResDto.remark};
          // this.projectDetailFiles = projectDetailFiles;
          // this.visitDetailResDtos = res.data.visitDetailResDtos || [];
          this.projectDetailResDto = {
            projectHighlights: res.data.dataMap.resultsEvaluated.projectHighlights,
            projectShortcoming: res.data.dataMap.resultsEvaluated.projectShortcoming,
            projectChange: res.data.dataMap.resultsEvaluated.projectChange,
            benchmarkCase: res.data.dataMap.resultsEvaluated.benchmarkCase,
            successReason: res.data.dataMap.analyzeCause.successReason,
            failReason: res.data.dataMap.analyzeCause.failReason,
            start: res.data.dataMap.nextMove.start,
            keep: res.data.dataMap.nextMove.keep,
            stop: res.data.dataMap.nextMove.stop,
            visitSituation: res.data.dataMap.visitSituation,
            trouble: res.data.dataMap.trouble,
            sumUpExperience: res.data.dataMap.sumUpExperience,
            remark: res.data.dataMap.remark.remark
          };
          // 报告详情附件
          if (res.data.dataMap.remark.file) {
            this.projectDetailFiles = {
              fileName: res.data.dataMap.remark.file.fileName,
              fileUrl: res.data.dataMap.remark.file.fileUrl,
              name: reportDetailResDto.staffName,
              createTime: reportDetailResDto.commitTime,
              orgTitle: reportDetailResDto.orgTitle || '',
              postName: reportDetailResDto.postName || ''
            };
          }
          // 评论记录
          this.comments = JSON.parse(JSON.stringify(res.data.comments));
          this.comments.forEach(item => {
            item.image = item.fileUrl ? item.fileUrl : '';
            item.fileName = item.fileName ? item.fileName + item.fileSuffix : '';
          });
          // 相关附件
          res.data.comments.forEach(item => {
            if (item.fileUrl) {
              this.fileComments.push(
                {
                  fileName: item.fileName,
                  fileUrl: item.fileUrl,
                  createTime: item.createTime,
                  content: item.content,
                  name: item.staffName,
                  orgTitle: item.orgTitle || '',
                  postName: item.postName || ''
                }
              );
            };
          });
          if (reportDetailResDto.fileUrl) this.fileComments.unshift({ ...this.projectDetailFiles });
          // 接收人
          res.data.senders.forEach(item => {
            const itemObj = {
              name: item.staffName,
              status: item.readFlag === 1 ? '已读' : '未读'
            };
            if (item.sendType === 1) {
              this.primarySender.push({ ...itemObj, text: itemObj.name, dep: false });
            } else {
              this.recipientList.push({ ...itemObj, text: itemObj.name, dep: false });
            }
          });
          // 评论@人
          const creatName = reportDetailResDto.staffName.replace(/\(/, '（').replace(/\)/, '）');
          this.aitPersonList = [...this.primarySender, ...this.recipientList, { name: creatName, text: creatName }];
          this.aitPersonList = this.aitPersonList.filter(item => item.text.indexOf(this.userInfo.userInfoRes.userName) === -1);
        } else {
          this.$showToast(res.errorMsg);
        }
      });
    },
    changePage(current) {
      this.initialIndex = current;
      this.scrollY = 0;
      // 滑动至 评论记录
      if (current === 2) {
        this.$nextTick(() => {
          const height = this.$refs.commentWrap[0].offsetHeight;
          this.tabList[2].height = height < 568 ? 568 : height;
        });
      }
      if (current === 3) {
        this.$nextTick(() => {
          const height = this.$refs.fileCommentWrap[0].offsetHeight;
          this.tabList[3].height = height;
        });
      }
      // 更新滚动距离
      // this.$refs.scroll.forceUpdate();
      // this.$refs.scroll.refresh();
    },
    getTabList() {
      const type = this.$route.params.type;
      if (type === '1') {
        this.tabList.push({ label: '负责销售', value: 2 });
      }
      this.getAuthBtn(type);
    },
    toComment() {
      this.$router.push(
        {
          name: 'AddComments'
        }
      );
      this.SET_KEEPALIVE(['AddComments']);
    },
    checkReport() {
      this.popTitle = '报告接收人';
      this.$refs.customPopups.isShow = true;
      ModalHelper('modal-open').afterOpen();
    },
    openCatalogue() {
      this.popTitle = '目录';
      this.$refs.catalogue.isShow = true;
      ModalHelper('modal-open').afterOpen();
    },
    cancelActive(anchor) {
      this.DirectoryList.forEach(itemA => {
        itemA.list.forEach(itemB => {
          itemB.activate = false;
        });
      });
      this.$refs.catalogue.isShow = false;
      if (anchor) this.scrollTo(anchor);
      ModalHelper('modal-open').beforeClose();
    },
    scrollTo(anchor) {
      var element = document.getElementById(anchor);
      // var detailsBox = document.getElementsByClassName('review-detailts')[0];
      // 44 描点slide高度
      // 53 为吸附状态头部高度
      // 100 为无吸附状态头部高度
      this.$nextTick(() => {
        const scrollInstance = element.offsetTop + 100; // 滚动距离
        const scrollerHeight = this.$refs.scroll.scroll.scrollerHeight; // 滚动条高度
        if ((scrollInstance + (document.body.offsetHeight - 53) - scrollerHeight) < 0) {
          this.$refs.scroll.scrollTo(
            0,
            -(element.offsetTop + 100),
            700,
            ease['bounce']
          );
        } else {
          this.$refs.scroll.scrollTo(
            0,
            -(scrollerHeight - (document.body.offsetHeight - 53)),
            700,
            ease['bounce']
          );
        }
      });

      // if (element.offsetTop > (detailsBox.offsetHeight - (document.body.offsetHeight - 53) + 100)) {
      //   this.$refs.scroll.scrollTo(
      //     0,
      //     -(element.offsetTop + 100),
      //     700,
      //     ease['bounce']
      //   );
      // } else {
      //   this.$refs.scroll.scrollTo(
      //     0,
      //     -(element.offsetTop + 100),
      //     700,
      //     ease['bounce']
      //   );
      // }
    },
    scroll(pos) {
      const x = Math.abs(pos.x);
      const tabItemWidth = this.$refs.tabNav.$el.clientWidth;
      const slideScrollerWidth = this.$refs.slide.slide.scrollerWidth;
      const deltaX = (x / slideScrollerWidth) * tabItemWidth;
      this.$refs.tabNav.setSliderTransform(deltaX);
    },
    // 报告详情tab
    changeHandler(cur) {
      this.current = cur;
    },
    move(cur) {
      const index = this.labels.indexOf(cur);
      this.scrollTo(this.idList[index]);
    },
    // 添加评论
    selectPerson() {
      this.$refs.aitPersonPop.isShow = true;
    },
    subscriptOperat(index) {
      this.aitPersonList[index].dep = true;
      this.$refs.aitPersonPop.isShow = false;
      this.$refs.additionComments.validity(this.aitPersonList[index].text);
      this.aitPersonList.forEach(item => { item.dep = false; });
      // 重新获取焦点
      // this.blur();
      if (this.$refs.additionComments) {
        this.$refs.additionComments.$refs.textarea.focus();
      }
    },
    blur() {
      if (this.$refs.additionComments) {
        this.$refs.additionComments.blur();
      }
    },
    refresh() {
      this.$nextTick(() => {
        this.$refs.scroll.refresh();
        this.$refs.scroll.forceUpdate();
        // this.$refs.scroll.scrollTo(
        //   0,
        //   -200,
        //   700,
        //   ease['bounce']
        // );
      });
    }
  }
};
</script>

<style lang="scss"  scoped>
  .sticky-view-complex {
    .content {
      .sticky-view-container {
        position: absolute;
        top: 0;
        bottom: 59px;
        left: 0;
        width: 100%;
        background-color: $color-FFF;
        li {
          padding: 20px 10px;
        }
        .sticky-header {
          background-color: #666;
        }
        .cube-sticky{
          .cube-scroll-wrapper {
            // background-color: $color-FFF;
          }
        }
        .cube-sticky-ele-on{
          visibility: hidden;
        }
        .cube-sticky-fixed{
          .sticky-header {
            margin: 0 10px;
            background-color: transparent;
            li {
              background-color: #666;
            }
          }
        }
      }
    }
  }

  .dialog-detail{
    z-index: 102;
    height: calc(90vh - 61px);
    overflow-y: auto;
    .dialog-content{
      margin: 14px 15px 22px 15px;
      .dialog-content-title{
        margin-bottom: 14px;
        color: $color-999;
        font-size: $font-12;
        text-align: left;
      }
    }
    .dialog-content:last-child{
      margin: 14px 15px 0 15px;
    }
  }

.heder-gap{
  width: 100%;
  height: 10px;
  background-color: $color-F7;
}
.catalogue-mark{
  position: fixed;
  right: 15px;
  bottom: 94px;
  z-index: 99;
  width: 44px;
  height: 44px;
  background-image: url('../../assets/img/btn_mulu.png');
  background-size: 44px 44px;
  border-radius: 50%;
}
.errorPage{
  height: calc(100vh - 168px);
}
.comments-postion{
  position: absolute;
  bottom: 0;
  z-index: 10;
  width: 100%;
  max-height: 200px;
  box-shadow: 0 -4px 8px 0 rgba(0,0,0,0.06);
}

.side-crosswise{
  padding-left: 10px;
  background-color: $color-FFF;
  .tabBar-item{
    padding: 8px 12px;
    background-color: $color-F7;
    border-radius: 15px;
    .tabBar-text{
      color: $color-333;
    }
  }
  .tabBar-active{
    background: rgba(0,164,255,0.1);
    .tabBar-text{
      color: $color-theme;
    }
  }
  .cube-scroll-nav-bar-item{
    padding: 10px 5px;
  }
}

// 评论人
.filter-ul{
  margin-left: 16px;
}
</style>

