<template>
  <section class="review-detailts">
    <div class="side-container">
      <cube-scroll-nav-bar
        ref="scrollNavBar"
        direction="vertical"
        :current="current"
        :labels="labels"
        :txts="txts"
        :options="options"
        @change="changeHandler"
      >
        <div
          slot-scope="props"
        >
          <div
            v-if="props.label === '评估结果'"
            id="commentResults"
            class="nav-chunk"
          >
            <customTitle
              title="一、评估结果"
              :is-query="true"
              @dialogOperation="dialogOperation('评估结果')"
            />
            <div class="comment-content">
              <accordion-com
                ref="accordionComA"
                title="1.项目亮点"
                @refresh="refresh"
              >
                <div
                  v-for="(item, index) of projectDetailResDto.projectHighlights"
                  :key="index"
                  class="comment-content-marginB"
                >
                  <icon-view :title="item.custName" />
                  <work-text
                    title="亮点做法"
                    :content="item.highlightApproach"
                  />
                  <work-text
                    title="结果与影响"
                    :content="item.resultAndImpact"
                  />
                </div>
              </accordion-com>
              <accordion-com
                ref="accordionComB"
                title="2.项目不足"
                @refresh="refresh"
              >
                <div
                  v-for="(item, index) of projectDetailResDto.projectShortcoming"
                  :key="index"
                  class="comment-content-marginB"
                >
                  <icon-view :title="item.custName" />
                  <work-text
                    title="不足做法"
                    :content="item.inadequateApproach"
                  />
                  <work-text
                    title="结果与影响"
                    :content="item.resultAndImpact"
                  />
                  <work-text
                    title="改进方案"
                    :content="item.improvePlan"
                  />
                </div>
              </accordion-com>
              <accordion-com
                ref="accordionComC"
                title="3.项目变化项"
                @refresh="refresh"
              >
                <div
                  v-for="(item, index) of projectDetailResDto.projectChange"
                  :key="index"
                  class="comment-content-marginB"
                >
                  <icon-view :title="item.custName" />
                  <work-text
                    title="变化内容"
                    :content="item.changeContent"
                  />
                  <work-text
                    title="变化影响"
                    :content="item.changeImpact"
                  />
                  <work-text
                    title="应对方案"
                    :content="item.responsePlan"
                  />
                  <work-text
                    title="结果"
                    :content="item.result"
                  />
                </div>
              </accordion-com>
              <accordion-com
                ref="accordionComD"
                title="4.标杆案例"
                @refresh="refresh"
              >
                <div
                  v-for="(item, index) of projectDetailResDto.benchmarkCase"
                  :key="index"
                  class="comment-content-marginB"
                >
                  <icon-view :title="item.custName" />
                  <work-text
                    title="行业"
                    :content="item.industry"
                  />
                  <work-text
                    title="方案"
                    :content="item.plan"
                  />
                  <work-text
                    title="启发与行动"
                    :content="item.inspirationAndAction"
                  />
                </div>
              </accordion-com>
              <div
                class="putaway-btn"
                @click="putAway"
              >
                {{ foldOpenText }}
              </div>
            </div>
          </div>
          <div
            v-if="props.label === '分析原因'"
            id="analyzeCause"
            class="nav-chunk"
          >
            <customTitle
              title="二、分析原因"
            />
            <div class="comment-content">
              <work-text
                title="成功原因"
                :content="projectDetailResDto.successReason"
              />
              <work-text
                title="失败原因"
                :content="projectDetailResDto.failReason"
              />
            </div>
          </div>
          <div
            v-if="props.label === '下一步行动'"
            id="nextAction"
            class="nav-chunk"
          >
            <customTitle
              title="三、下一步行动"
            />
            <div class="comment-content">
              <work-text
                title="开始做"
                :content="projectDetailResDto.start"
              />
              <work-text
                title="持续做"
                :content="projectDetailResDto.keep"
              />
              <work-text
                title="停止做"
                :content="projectDetailResDto.stop"
              />
            </div>
          </div>
          <div
            v-if="props.label === '本周拜访情况'"
            id="visitThisWeek"
            class="nav-chunk"
          >
            <customTitle
              title="四、本周拜访情况"
            />
            <div
              v-for="(item, index) of projectDetailResDto.visitSituation"
              :key="index"
              class="comment-content comment-content-marginB"
            >
              <icon-view :title="item.custName" />
              <work-text
                title="基本情况"
                :content="item.basicSituation"
              />
              <work-text
                title="拜访人"
                :content="item.visitor"
              />
              <work-text
                title="客户需求"
                :content="item.customerDemand"
              />
              <work-text
                title="目前进展"
                :content="item.currentProgress"
              />
              <work-text
                title="下一步计划"
                :content="item.nextPlan"
              />
              <work-text
                title="思考"
                :content="item.thinking"
              />
            </div>
          </div>
          <div
            v-if="props.label === '烦恼'"
            id="annoyance"
            class="nav-chunk"
          >
            <customTitle
              title="五、烦恼"
            />
            <work-text
              :content="projectDetailResDto.trouble"
            />
          </div>
          <div
            v-if="props.label === '总结经验'"
            id="sumupExperience"
            class="nav-chunk"
          >
            <customTitle
              title="六、总结经验"
              :is-query="true"
              @dialogOperation="dialogOperation('总结经验')"
            />
            <work-text
              :content="projectDetailResDto.sumUpExperience"
            />
          </div>
          <div
            v-if="props.label === '补充说明'"
            id="supplementaryNotes"
            class="nav-chunk"
            style="margin: 0"
          >
            <customTitle
              title="七、补充说明"
              @dialogOperation="dialogOperation('总结经验')"
            />
            <work-text
              :content="projectDetailResDto.remark"
            />
          </div>
        </div>
      </cube-scroll-nav-bar>
      <div class="comment-content">
        <related-accessory
          v-if="projectDetailFiles.fileUrl"
          :background-color-change="true"
          :data="projectDetailFiles"
        />
      </div>
    </div>
  </section>
</template>

<script>
import customTitle from '_c/review/custom-title';
import accordionCom from '_c/accordion';
import iconView from '_c/view-match/icon-view';
import workText from '_c/review/work-text';
import relatedAccessory from '_c/tab-card/related-accessory';
export default {
  components: {
    customTitle,
    accordionCom,
    iconView,
    workText,
    relatedAccessory
  },
  props: {
    current: {
      type: String,
      default: ''
    },
    projectDetailResDto: {
      type: Object,
      default: () => {
        return {};
      }
    },
    projectDetailFiles: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    return {
      labels: [
        '评估结果',
        '分析原因',
        '下一步行动',
        '本周拜访情况',
        '烦恼',
        '总结经验',
        '补充说明'
      ],
      txts: [
        '评估结果',
        '分析原因',
        '下一步行动',
        '本周拜访情况',
        '烦恼',
        '总结经验',
        '补充说明'
      ],
      options: {
        click: false
      },
      contents: '111',
      content: '坚持大客户长期开发战略，经过半年多的跟进，了解到客户全国主要供应商为人瑞，但是武汉的招聘客户不是很满意，以此为突破口，已陆续拿到客户全国的需求，并开始协调。',
      foldOpenText: '展开全部'
    };
  },
  methods: {
    changeHandler(cur) {
      // this.current = cur;
      this.$emit('changeHandler', cur);
    },
    putAway() {
      let arr = ['accordionComA', 'accordionComB', 'accordionComC', 'accordionComD'];
      if (this.foldOpenText === '收起全部') {
        arr.forEach(item => {
          this.$refs[item].showHide = false;
        });
        // this.refresh();
      } else {
        arr.forEach(item => {
          this.$refs[item].showHide = true;
        });
        // this.refresh();
      }
      this.foldOpenText = this.foldOpenText === '收起全部' ? '展开全部' : '收起全部';
    },
    refresh() {
      let arr = ['accordionComA', 'accordionComB', 'accordionComC', 'accordionComD'];
      const expandAllFlag = arr.every(item=> this.$refs[item].showHide);
      if (expandAllFlag) {
        this.foldOpenText = '收起全部';
      }
      const closeAllFlag = arr.every(item=> !this.$refs[item].showHide);
      if (closeAllFlag) {
        this.foldOpenText = '展开全部';
      }

      // this.$refs.scrollNavBar.refresh();
      this.$emit('refresh');
    },
    dialogOperation(text) {
      let copywriting = '';
      switch (text) {
      case '评估结果':
        copywriting = '亮点与不足（包括不限于：需求开发、项目执行、项目风险、分公司管理等）';
        break;
      case '总结经验':
        copywriting = '总结规律及延伸思考';
        break;
      case '补充说明':
        copywriting = '未在以上事项中阐述的内容';
        break;
      }
      this.dialog = this.$createDialog(
        {
          type: 'alert',
          showClose: false,
          confirmBtn: {
            text: '我知道了',
            active: true
          }
        },
        createElement => [
          createElement(
            'div',
            {
              class: {
                'my-details': true
              },
              slot: 'content'
            },
            copywriting
          )
        ]
      );
      this.dialog.show();
    }
  }
};
</script>

<style lang="scss" scoped>
.review-detailts{
  background-color: $color-FFF;
  .nav-chunk{
    margin-bottom: 10px;
    padding: 24px 0;
    overflow: hidden;
    background-color: $color-FFF;
  }
  .comment-content{
    margin: 0 15px;
  }
  .comment-content-marginB{
    margin-bottom: 15px;
  }
  .putaway-btn{
    position:relative;
    height:46px;
    color: $color-theme;
    font-size: $font-16;
    line-height: 70px;
    text-align: center;
    border-top: 1px solid transparent;
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 1px;
      background: $color-E5;
      transform: scaleY(0.5);
      content: '';
    }
  }
}
</style>
