<template>
  <div class="visit-box">
    <i class="icon-joyo visit-icon">{{ data.icon }}</i>
    <div :class="{'visit-content' : isProgress, 'visit-content-self': !isProgress }">
      <div class="visit-value">
        <div class="visit-text-percentage">
          <div class="visit-text">
            {{ data.text }}
          </div>
          <div
            v-if="isProgress"
            class="visit-percentage"
          >
            {{ data.percentage }}
          </div>
        </div>
        <span class="visit-num">{{ data.num }}</span>
      </div>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    },
    isProgress: {
      type: Boolean,
      default: true
    }
  },
  mounted() {
    console.log(this.data);
  }
};
</script>

<style lang="scss" scoped>
.visit-box{
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
  .visit-icon{
    width: 38px;
    height:38px;
    font-size: 18px;
    line-height: 38px;
    text-align: center;
    background-color: $color-F7;
    border-radius: 2px;
  }
  .visit-content{
    flex: 1;
    margin-left: 12px;
    .visit-value{
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      font-size: $font-16;
      line-height: 22px;
      .visit-text-percentage{
        display: flex;
        justify-content: flex-start;
        .visit-text{
          margin-right: 6px;
          color: $color-333;
        }
        .visit-percentage{
          color: $color-999;
        }
      }
      .visit-num{
        margin-right: 15px;
        color: $color-333;
        font-weight: bold;
        font-size: 18px;
      }
    }
  }
  .visit-content-self{
    align-self: center;
    @extend .visit-content;
    .visit-value{
      margin: 0;
    }
  }
}
</style>
