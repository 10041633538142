<template>
  <div class="title-box">
    <div class="title-annotation" />
    <div class="title-h1">
      {{ title }}
    </div>
    <div
      v-if="isQuery"
      class="title-icon"
      @click="dialogOperation"
    />
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    isQuery: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    dialogOperation() {
      this.$emit('dialogOperation');
    }
  }
};
</script>

<style lang="scss" scoped>
.title-box{
  display: flex;
  justify-content: flex-start;
  margin-bottom: 24px;
  .title-annotation{
    width: 5px;
    height:24px;
    margin-right: 15px;
    background-color: $color-theme;
  }
  .title-h1{
    margin-right: 6px;
    color: $color-333;
    font-weight: 600;
    font-size: 18px;
    line-height: 25px;
  }
  .title-icon{
    align-self: center;
    width: 20px;
    height: 20px;
    background-image: url('~@/assets/img/icon_shuoming.png');
    background-size:20px 20px;
  }
}
</style>
