<template>
  <div class="dialog-title">
    <div class="dialog-title-name">
      {{ title }}
    </div>
    <div
      class="dialog-imgbox"
      @click="hide"
    >
      <img
        src="@/assets/img/icon_pop_close.png"
        alt=""
      >
    </div>
  </div>
</template>

<script>
const COMPONENT_NAME = 'popup-header';
export default {
  name: COMPONENT_NAME,
  props: {
    title: {
      type: String,
      default: '弹窗'
    }
  },
  methods: {
    hide() {
      this.$parent.hide();
    }
  }
};

</script>

<style  lang="scss"  scoped>
.dialog-title{
  position: relative;
  padding: 18px 0;
  overflow: hidden;
  .dialog-title-name{
    color:$color-333;
    font-weight: 600;
    font-size: $font-18;
    line-height: 25px;
    text-align: center;
  }
}
.dialog-imgbox{
  position: absolute;
  top:15px;
  right:15px;
  width:24px;
  height:24px;
  img{
    width:24px;
  }
}
</style>
