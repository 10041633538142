<template>
  <div
    :id="id"
    class="charts-style"
    :style="{width: size.width, height: size.height}"
  />
</template>
<script>
export default {
  props: {
    id: {
      type: String,
      default: 'main'
    },
    data: {
      type: Object,
      default: () => {
        return {};
      }
    },
    size: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  methods: {
    drawLine(data) {
      // 基于准备好的dom，初始化echarts实例
      var chartDom = document.getElementById(`${this.id}`);
      var myChart = this.$echarts.init(chartDom);
      var option = {
        tooltip: {
          show: false
        },
        color: data.color,
        silent: true,
        series: [
          {
            type: 'pie',
            label: {
              show: false
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '40',
                fontWeight: 'bold'
              }
            },
            radius: ['80%', '100%'],
            avoidLabelOverlap: false,
            data: data.num
          }
        ]
      };

      option && myChart.setOption(option);
    }
  }
};
</script>

<style lang="scss" scoped>
.charts-style{
  width: 88px;
  height: 88px;
  margin:0 auto;
  margin-top: 24px;
  vertical-align: middle;
}
</style>
