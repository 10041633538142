<template>
  <section>
    <div
      class="target-num-a"
      :style="{fontSize: sizeNum}"
    >
      <span class="target-num-aa">{{ data.num }}</span>
      <span
        v-if="Number(data.decimal) !== 0"
        class="target-num-ab"
      >{{ `.${data.decimal }` }}</span>
      <span class="target-num-ab">{{ '%' }}</span>
    </div>
    <div class="target-num-t">
      {{ data.text }}
    </div>
  </section>
</template>
<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    },
    sizeNum: {
      type: String,
      default: ''
    }
  }
};
</script>

<style lang="scss" scoped>
.target-num-a{
  margin-bottom:4px;
  color: $color-333;
  .target-num-aa {
    font-weight: bold;
    font-size: 24px;
  }
  .target-num-ab {
    font-weight: bold;
    font-size: $font-18;
  }
}
.target-num-t{
  color: $color-999;
  font-size: $font-12;
}
</style>
