<template>
  <div class="header-box">
    <div class="header-details">
      <div class="header-time">
        {{ data.time }}
      </div>
      <div class="header-content">
        <div class="header-imgbox">
          <img
            :src="imgUrl"
            alt="头像"
          >
        </div>
        <slot>
          <div class="header-text">
            <div class="header-text-l">
              <div class="header-text-title">
                <span class="header-text-name">
                  {{ data.title ?  data.title.substring(0, data.title.indexOf('(')) : '' }}
                </span>
                <span class="header-text-company">
                  {{ data.company }}
                </span>
              </div>
              <div class="header-text-time">
                {{ data.creatTime }}
              </div>
            </div>
            <div
              class="header-text-r"
              @click="operation"
            >
              <span class="header-text-name">报告接收人</span>
              <i class="header-text-arrows" />
            </div>
          </div>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object
    },
    imgUrl: {
      type: String,
      default: require('../../assets/img/person.png')
    },
    diminish: {
      type: Boolean
    }
  },
  methods: {
    operation() {
      console.log('2222222222');
      let checkReport = 'checkReport';
      this.$emit(checkReport);
    }
  }

};
</script>

<style lang="scss" scoped>
.header-box{
  width:100%;
  height: 105px;
  overflow: hidden;
  background: $color-FFF;
  .header-details{
    margin: 15px 15px 0 15px;
    .header-time{
      margin-bottom: 12px;
      color: $color-333;
      font-weight: 600;
      font-size: $font-16;
      line-height: 22px;
      text-align: left;
    }
    .header-content{
      display: flex;
      .header-imgbox{
        width:40px;
        height:40px;
        margin-right: 8px;
        background-color:$color-01;
        border-radius: 50%;
        img{
          width:40px;
          border-radius: 50%;
        }
      }
    }
    .header-text{
      display: flex;
      flex: 1;
      justify-content: space-between;
      margin-top: 2px;
      .header-text-l{
        .header-text-title{
          margin-bottom: 2px;
          text-align: left;
          .header-text-name{
            color: $color-333;
            font-size: $font-14;
            line-height: 20px;
          }
          .header-text-company{
            margin-left: 4px;
            color: $color-999;
            font-size: $font-10;
            line-height: 14px;
            // width: 120px;
            // overflow:hidden; //超出的文本隐藏
            // text-overflow:ellipsis; //溢出用省略号显示
            // white-space:nowrap; //溢出不换行
          }
        }
        .header-text-time{
          color: $color-999;
          font-size: $font-12;
          line-height: 17px;
          text-align: left;
        }
      }
      .header-text-r{
        display: flex;
        align-self:flex-end;
        justify-content: flex-start;
        color: $color-theme;
        .header-text-name{
          font-size: $font-14;
          line-height: 20px;
        }
        .header-text-arrows{
          align-self: center;
          width:6px;
          height: 10px;
          margin-left: 2px;
          background-image: url('../../assets/img/new_arrows.png');
          background-size: 6px 10px;
        }
      }
    }
  }
}
</style>
