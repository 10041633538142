<template>
  <div class="workText-box">
    <div
      v-if="title"
      class="workText-title"
    >
      <div class="workText-dot" />
      <div class="workText-h2">
        {{ title }}
      </div>
    </div>
    <div class="workText-content">
      {{ content }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ''
    },
    content: {
      type: String,
      default: ''
    }
  }
};
</script>

<style lang="scss" scoped>
.workText-box{
  margin-top:24px;
  .workText-title{
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .workText-dot{
      width: 4px;
      height: 4px;
      margin-right: 8px;
      background-color: $color-333;
      border-radius: 2px;
    }
    .workText-h2{
      color: $color-333;
      font-size: $font-16;
    }
  }
  .workText-content{
    margin: 12px 12px 0 12px;
    color: $color-333;
    font-size: $font-16;
    line-height: 29px;
    text-align: left;
  }
}
</style>
